import { useContext, useState } from 'react';

import { CreateVirtualMachineFields } from '../components/controls/CreateVirtualMachine';
import { SnackBarContext } from '../providers/SnackbarProvider';
import { request } from '../util/request';
import { CreateContainerFields } from 'src/components/controls/CreateContainer';

export interface VirtualMachineRequest {
  customerId: string;
  vmName: string;
  imageName: string;
  cpuCount: number;
  memMiB: number;
  diskSpace: number;
  labels: Record<string, string>;
}

export interface CreateContainerRequest {
  customerId: string;
  helmChartName: string;
  releaseName: string;
  description: string;
}

export default () => {
  const [open, setOpen] = useState(false);
  const [createContainerOpen, setCreateContainerOpen] = useState(false);
  const snackbar = useContext(SnackBarContext);

  const handleClickOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };
  const handleCreateContainerOpen = (): void => {
    setCreateContainerOpen(true);
  };
  const handleCreateContainerClose = (): void => {
    setCreateContainerOpen(false);
  };

  const getVmList = () => {
    return request(
      `${process.env.GATSBY_API_LIST_VMS}?key=${process.env.GATSBY_API_KEY}&customerId=${process.env.GATSBY_CUSTOMER_ID}`,
      {
        method: 'GET',
        mode: 'cors',
      }
    );
  };

  const getContainerList = () => {
    return request(
      `${process.env.GATSBY_API_LIST_CONTAINERS}?key=${process.env.GATSBY_API_KEY}&customerId=${process.env.GATSBY_CUSTOMER_ID}`,
      {
        method: 'GET',
        mode: 'cors',
      }
    );
  };

  const handleChange = async (
    data: CreateVirtualMachineFields
  ): Promise<void> => {
    try {
      const body: VirtualMachineRequest = {
        customerId: process.env.GATSBY_CUSTOMER_ID as string,
        vmName: data.name,
        imageName: data.image,
        cpuCount: data.cpus,
        memMiB: data.ram,
        diskSpace: data.diskSpace as number,
        labels: data.labels,
      };

      await request(
        `${process.env.GATSBY_API_HOST}?key=${process.env.GATSBY_API_KEY}`,
        {
          method: 'POST',
          body: JSON.stringify(body),
          mode: 'cors',
        }
      );

      snackbar.showFeedback({
        message: 'Your VM has been submitted',
        duration: 3500,
      });
      handleClose();
    } catch (error) {
      snackbar.showFeedback({
        message: 'Failed to create VM',
        duration: 3500,
        severity: 'error',
      });
      console.error(error);
    }
  };

  const handleCreateContainerChange = async (
    data: CreateContainerFields
  ): Promise<void> => {
    try {
      const body: CreateContainerRequest = {
        customerId: 'c8bea1c8-ae80-40f6-95e5-619a003aeb58',
        helmChartName: data.helmChartName,
        releaseName: data.releaseName,
        description: data.description,
      };

      await request(
        `${process.env.GATSBY_API_CREATE_CONTAINER}?key=${process.env.GATSBY_API_KEY}`,
        {
          method: 'POST',
          body: JSON.stringify(body),
          mode: 'cors',
        }
      );

      snackbar.showFeedback({
        message: 'Successfully Created Container',
        duration: 3500,
      });
      handleCreateContainerClose();
    } catch (error) {
      snackbar.showFeedback({
        message: 'Failed to create Container',
        duration: 3500,
        severity: 'error',
      });
      console.error(error);
    }
  };

  return {
    open,
    handleClickOpen,
    handleClose,
    handleChange,
    createContainerOpen,
    handleCreateContainerOpen,
    handleCreateContainerClose,
    handleCreateContainerChange,
    getContainerList,
    getVmList,
  };
};
