import React, { FunctionComponent } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export interface MonthlyChargesChartProps {
  data: { month: string; amount: number }[];
}

export const MonthlyChargesChart: FunctionComponent<MonthlyChargesChartProps> = ({
  data,
}) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
      legend: {
        color: theme.palette.secondary.main,
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        color: theme.palette.secondary.contrastText,
        padding: '1rem',
        borderRadius: '.25rem',
      },
    });
  });

  const classes = useStyles();

  const legendTheme = (value: number) => {
    return <span className={classes.legend}>{value}</span>;
  };

  interface CustomTooltipProps {
    active?: boolean;
    payload?: string | any;
    label?: string;
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && label) {
      return (
        <div className={classes.tooltip}>
          {`${label} : ` + `$` + `${payload[0].value}`}
        </div>
      );
    }
    return null;
  };

  const formatter = (value: number) => {
    console.log(`$${value}`);
    return `$${value}`;
  };
  return (
    <>
      <LineChart
        width={350}
        height={300}
        margin={{
          top: 24,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" stroke={theme.palette.primary.main} />
        <YAxis
          domain={[100, 3500]}
          tickFormatter={formatter}
          stroke={theme.palette.primary.main}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend formatter={legendTheme} />

        <Line
          type="monotone"
          dataKey="amount"
          stroke={theme.palette.secondary.main}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </>
  );
};

export default MonthlyChargesChart;
