import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { FunctionComponent } from 'react';
import CardHeading from '../card-heading/CardHeading';
import ProjectedCapacityChart from '../charts/ProjectedCapacityChart';

export interface ProjectedCapacityProps {
  height: number;
  good: { x: number; y: number }[];
  over: { x: number; y: number }[];
}

const staticText = {
  title: 'Projected Capacity',
  caption1: 'Months before reaching 80% capacity: 4',
  caption2: 'Months of capacity remaining: 7',
  headingLink: '/compute',
  provisionCapacityButtonText: 'Provision Capacity',
  provisionCapacityLink:
    'https://www.cisco.com/c/en/us/products/hyperconverged-infrastructure/index.html?dtid=osscdc000283',
};

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      padding: '1rem 1rem 1rem 3.5rem',
      margin: 'auto',
    },
  })
);

export const ProjectedCapacity: FunctionComponent<ProjectedCapacityProps> = ({
  good,
  over,
  height,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box flex={1} className={classes.box} {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardHeading
            title={staticText.title}
            endIcon={<ArrowForwardIcon />}
            link={staticText.headingLink}
          />
        </Grid>
        <Grid item xs={12} style={{ height }}>
          <ProjectedCapacityChart good={good} over={over} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant={'h6'}>{staticText.caption1}</Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant={'h6'}>{staticText.caption2}</Typography>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            variant={'contained'}
            color={'primary'}
            href={staticText.provisionCapacityLink}
            target="_blank"
          >
            {staticText.provisionCapacityButtonText}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectedCapacity;
