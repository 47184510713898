import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, { FunctionComponent } from 'react';
import CardHeading from '../card-heading/CardHeading';
import MonthlyChargesChart from '../charts/MonthlyChargesChart';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export interface MonthlyChargesProps {
  height: number;
  data: { month: string; amount: number }[];
}

const staticText = {
  title: 'Last 6 Months',
  subtitle: 'August 2020 - February 2021',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      padding: theme.spacing(2),
      margin: 'auto',
    },
  })
);

export const MonthlyCharges: FunctionComponent<MonthlyChargesProps> = ({
  data,
  height,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box flex={1} className={classes.box} {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardHeading
            title={staticText.title}
            subtitle={staticText.subtitle}
          />
        </Grid>
        <Grid item xs={12} style={{ height }}>
          <MonthlyChargesChart data={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MonthlyCharges;
