import { CartesianMarkerProps } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import React, { FunctionComponent } from 'react';

const theme = {
  axis: {
    ticks: {
      text: {
        fill: 'white',
      },
    },
  },
};

export interface ProjectedCapacityChartProps {
  good: { x: number; y: number }[];
  over: { x: number; y: number }[];
}

export const ProjectedCapacityChart: FunctionComponent<ProjectedCapacityChartProps> = ({
  good,
  over,
}: ProjectedCapacityChartProps) => {
  const currMonthMarkerValue = new Date().getMonth();
  const markers: CartesianMarkerProps[] = [
    {
      axis: 'x' as const,
      value: currMonthMarkerValue,
      lineStyle: { stroke: '#aaa', strokeDasharray: '3, 3', strokeWidth: 2 },
    },
    {
      axis: 'y' as const,
      value: 80,
      lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
    },
  ];

  const thresholdPercent = 80;
  const thresholdDataPoint = good.find((d) => d.y === thresholdPercent);
  if (thresholdDataPoint) {
    markers.push({
      axis: 'x' as const,
      value: thresholdDataPoint.x,
      lineStyle: { stroke: '#b0413e', strokeWidth: 2 },
    });
  }

  return (
    <ResponsiveLine
      curve="monotoneX"
      enableArea={true}
      enablePoints={false}
      margin={{ top: 20, right: 15, bottom: 30, left: 50 }}
      data={[
        {
          id: 'At or below threshold',
          data: good,
        },
        {
          id: 'Over threshold',
          data: over,
        },
      ]}
      colors={['#55D455', '#ffbf00']}
      xScale={{
        type: 'linear',
        min: Math.min(...good.map((data) => data.x)),
        max: 'auto',
      }}
      axisLeft={{
        orient: 'left',
        format: (v) => `${v}%`,
      }}
      axisBottom={{
        orient: 'bottom',
        format: (v) => {
          let m = v as number;
          m = m <= 11 ? m : Math.floor(m / 10) * m - 12;
          return moment(new Date().setMonth(m)).format('MMM').toUpperCase();
        },
      }}
      markers={markers}
      theme={theme}
    />
  );
};

export default ProjectedCapacityChart;
