import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';
import { WorkloadCountChart } from '../../components/charts/WorkloadCountChart';
import useVirtualizationPage from '../../hooks/useVirtualizationPage';
import { costPerProjectData } from '../billing/billing-mock-data';
import { CapacityPlanningBarChart } from '../charts/CapacityPlanningBarChart';
import { projectedCapacityChartMockData } from '../charts/projectedCapacityMockData';
import { CostPerProject } from '../cost-per-project/CostPerProject';
import CurrentBillSnapshot from '../current-bill-snapshot/CurrentBillSnapshot';
import DocumentationFeed from '../documentation-feed/DocumentationFeed';
import { MonthlyCharges } from '../monthly-cost/MonthlyCharges';
import NewsFeed from '../news-feed/NewsFeed';
import { ProjectedCapacity } from '../projected-capacity';
import { dashboardMockData } from './dashboard-mock-data';

const useStyles = makeStyles((theme: Theme) => ({
  pageToolbar: {
    display: 'flex',
    padding: '0 .25rem 0 1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
  },
  pageHeader: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    fontWeight: 300,
    fontSize: '1.25rem',
  },
  pageTitle: {
    fontFamily: 'Futura, Tw Cen MT',
  },
  spc: {
    width: '1rem',
  },
  customizeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonTextFix: {
    marginTop: '.15rem',
  },
}));

export const Dashboard: React.FC = () => {
  const {
    articleList,
    documentationList,
    monthlyCharges,
    capacityPlanningBarData,
  } = dashboardMockData;

  const classes = useStyles();

  const { getContainerList, getVmList } = useVirtualizationPage();

  return (
    <Box flex={1}>
      <Box className={classes.pageToolbar}>
        <div className={classes.pageHeader}>
          <DashboardIcon />
          <div className={classes.spc}></div>
          <div className={classes.pageTitle}>Dashboard</div>
        </div>
        <div className={classes.customizeButton}>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            endIcon={<SettingsIcon />}
          >
            <span className={classes.buttonTextFix}>Customize</span>
          </Button>
        </div>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <CurrentBillSnapshot {...dashboardMockData.currentBillSnapshot} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <MonthlyCharges height={400} data={monthlyCharges.data} />
        </Grid>
        <Grid item xs={4}>
          <CostPerProject data={costPerProjectData} height={400} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ProjectedCapacity
            good={projectedCapacityChartMockData.good}
            over={projectedCapacityChartMockData.over}
            height={400}
          />
        </Grid>
        <Grid item xs={4}>
          <CapacityPlanningBarChart
            data={capacityPlanningBarData.data}
            height={488}
          />
        </Grid>
        <Grid item xs={4}>
          <WorkloadCountChart
            {...{
              getVmList,
              getContainerList,
            }}
            height={400}
          />
        </Grid>
      </Grid>
      <Box mt={3} mb={3}></Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <NewsFeed articles={articleList} />
        </Grid>
        <Grid item xs={6}>
          <DocumentationFeed documentations={documentationList} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
