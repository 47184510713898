import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import CardHeading from '../card-heading/CardHeading';

export interface DocumentationFeedProps {
  documentations: DocumentationFeedItemProps[];
}

interface DocumentationFeedItemProps {
  title: string;
  to?: string;
  external?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 1),
  },
  header: {
    padding: theme.spacing(0, 2),
  },
  icons: {
    padding: theme.spacing(0),
  },
  bottomSection: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  newsItems: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  newsItem: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  hover: {
    '&:hover': {
      'background-color': 'rgba(0,0,0,0.15)',
      cursor: 'pointer',
    },
  },
}));

const staticText = {
  title: 'Documentation',
  readAll: 'Go to documentation',
};

const DocumentationFeed: FunctionComponent<DocumentationFeedProps> = (
  props
) => {
  const classes = useStyles();

  return (
    <Box flex={1} className={classes.root}>
      <CardHeading title={staticText.title} />
      <Divider variant="middle" className={classes.bottomSection} />
      <Grid container direction={'column'} className={classes.newsItems}>
        {props.documentations?.slice(0, 3)?.map((documentation, index) => (
          <Grid item className={classes.hover} key={index}>
            <Grid className={classes.newsItem}>
              <Typography>
                {documentation.to ? (
                  <Link
                    to={documentation.to}
                    target={documentation.external ? '_blank' : undefined}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {documentation.title}
                  </Link>
                ) : (
                  documentation.title
                )}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            startIcon={<ArrowForwardIcon />}
            style={{ marginTop: '1rem' }}
          >
            {staticText.readAll}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentationFeed;
